<template>
  <!--
    A red bar that displays the current environment (development / onsite / production)
  -->
  <div
    v-if="showEnvironmentBar"
    id="envBar"
  >
    <span class="redBg">
      <router-link
        :to="{ path: '/test' }"
      >{{ $t('testPage', { environment: store_getEnvironment }) }}</router-link></span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Environment',
  computed: {
    ...mapGetters([
      'store_getEnvironment'
    ]),
    showEnvironmentBar: function () {
      if (!this.store_getEnvironment) {
        return true;
      }
      if (this.store_getEnvironment.toLowerCase() === "production") {
        return false;
      }
      return true;
    }
  },
  created () {
    this.getSetting();
  },
  methods: {
    getSetting () {
      this.axios.get('/Setting/GetAll')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.$store.commit('store_setSetting', response.data);
        });
    }
  }
}
</script>

<style scoped>
#envBar {
  width: 100%;
  background: lightgray;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
}
#envBar span {
  height: 3px;
  padding: 2px 0;
  color: white;
  display: block;
  width: 100%;
  text-align: center;
}

#envBar a {
  color: white;
  text-decoration: none;
}

#envBar:hover span {
  height: auto;
}
</style>
